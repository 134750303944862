module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-8J9Z9L6YZQ","cookieName":"kunyit-gdpr-google-analytics","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kunyit - Rouwpsycholoog Karin Seydel","short_name":"Karin Seydel","start_url":"/","background_color":"#323662","theme_color":"#fa875a","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e7358b8dabe8588206e369bdc75ca7a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Barlow:500,600"]}},
    }]
